const Header = () => (
  <header className="bg-gray-900 bg-opacity-80 text-white p-4 relative z-10">
    <div className="container mx-auto flex justify-between items-center">
      <h1 className="text-2xl font-bold">ExcelAI</h1>
      <nav>
        <ul className="flex space-x-4">
          <li><a href="#about" className="hover:text-blue-400">About</a></li>
          <li><a href="#contact" className="hover:text-blue-400">Contact</a></li>
        </ul>
      </nav>
    </div>
  </header>
);

const Hero = () => (
  <section className="relative z-10 flex-grow flex items-center">
    <div className="container mx-auto text-center px-4">
      <h2 className="text-5xl font-bold mb-6 text-white">Excel Powered by AI</h2>
      <p className="text-xl mb-10 max-w-2xl mx-auto text-gray-300">Revolutionize your spreadsheets with artificial intelligence. Unlock insights, automate tasks, and make data-driven decisions faster than ever before.</p>
      <a href="https://ai-spreadsheet-next--new1-7ebce.us-central1.hosted.app" className="bg-blue-600 text-white px-8 py-3 rounded-lg text-lg hover:bg-blue-700 transition duration-300" >
        Free Trial
      </a>
      <div className="max-w-4xl mx-auto my-10">
        <div style={{position: 'relative', paddingBottom: '62.5%', height: 0}}>
          <iframe 
            src="https://www.loom.com/embed/bfa1a2ead12f4ca1a293872d21c85a36" 
            frameBorder="0" 
            webkitallowfullscreen="true"
            mozallowfullscreen="true" 
            allowFullScreen={true}
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
          ></iframe>
        </div>
      </div>
    </div>
  </section>
);

const Footer = () => (
  <footer className="bg-gray-900 bg-opacity-80 text-white py-8 relative z-10">
    <div className="container mx-auto text-center">
      <p>&copy; 2024 ExcelAI. All rights reserved.</p>
    </div>
  </footer>
);

const AnimatedBackground = () => {
  const lineStyle = {
    position: 'absolute',
    backgroundColor: 'rgba(59, 130, 246, 0.2)', // blue-500 with 0.2 opacity
  };

  return (
    <div className="fixed inset-0 z-0">
      <div className="absolute inset-0 bg-black opacity-90"></div>
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <div
            key={`row-${i}`}
            style={{
              ...lineStyle,
              height: '1px',
              left: '0',
              right: '0',
              top: `${5 * i}%`,
              animation: `moveRight ${20 + i * 0.5}s linear infinite`,
            }}
          />
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`col-${i}`}
            style={{
              ...lineStyle,
              width: '1px',
              top: '0',
              bottom: '0',
              left: `${5 * i}%`,
              animation: `moveDown ${20 + i * 0.5}s linear infinite`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

const App = () => (
  <div className="min-h-screen flex flex-col bg-black text-white relative overflow-hidden">
    <style jsx>{`
      @keyframes moveRight {
        0% { transform: translateX(-100%); }
        100% { transform: translateX(100%); }
      }
      @keyframes moveDown {
        0% { transform: translateY(-100%); }
        100% { transform: translateY(100%); }
      }
    `}</style>
    <AnimatedBackground />
    <Header />
    <main className="flex-grow flex flex-col">
      <Hero />
    </main>
    <Footer />
  </div>
);

export default App;